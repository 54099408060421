import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';

import Header from './components/Header';
import ProjectsView from './components/Projects/ProjectsView';
import StudioView from './components/Studio/StudioView';

import ProtectedRoute from './components/Login/ProtectedRoute';
import Login from './components/Login/Login';

import VoicesPage from './components/Voices/VoicesPage';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import { useUser } from './contexts/user-context';
import AuthError from './components/Login/auth-error';

const AuthErrorBoundary = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { error } = useUser();

    React.useEffect(() => {
        if (error && location.pathname !== '/auth-error') {
            navigate('/auth-error');
        }
    }, [error, navigate, location]);

    return children;
};

const App = () => {
    const { isLoggedIn, userPicture, logout } = useUser();
    return (
        <Router>
            <AuthErrorBoundary>
                <div className="App">
                    <Helmet>
                        <script type="text/javascript">
                            {`
                                (function(c,l,a,r,i,t,y){
                                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                })(window, document, "clarity", "script", "nx4cy33fbc");
                            `}
                        </script>
                    </Helmet>
                    <Header isLoggedIn={isLoggedIn} userPicture={userPicture} onLogout={logout} />
                    <Routes>
                        <Route path="/" element={isLoggedIn === null ? null : isLoggedIn ? <Navigate to="/projects" /> : <Login />} />
                        <Route path="/auth-error" element={<AuthError />} />
                        <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
                            <Route path="/projects" element={<ProjectsView />} />
                            <Route path="/project/:id" element={<StudioView />} />
                            <Route path="/voices" element={<VoicesPage />} />
                        </Route>
                    </Routes>
                </div>
            </AuthErrorBoundary>
        </Router>
    );
};

export default App;
