import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ isLoggedIn }) => {
    if (isLoggedIn === null) {
        // This is a null state when login state cannot be determined. Potentially show something here.
        // Users should never realistically end up seeing this
        return null;
    }
    return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
