import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteCameraShot = ({ show, instance, index, deleteCameraEntry, onHide }) => {
    const handleSubmit = () => {
        deleteCameraEntry(index);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{`Are you sure you want to delete ${instance?.type}?`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>Confirm you wish to delete {instance?.type}.</h2>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="danger" onClick={handleSubmit}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteCameraShot;
