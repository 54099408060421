import React from 'react';
import { useUser } from '../../contexts/user-context';
import Login from './Login';

export default function AuthError() {
    const { error } = useUser();
    return (
        <div>
            <h1>Authentication Error</h1>
            <p>{error}</p>
            <Login />
        </div>
    );
}
