import Cookies from 'js-cookie';
import { getEnvVariable } from './env';

const DATABASE_API_URL = getEnvVariable('REACT_APP_DATABASE_API_URL');

export const fetchWithToken = async (url, options = {}, retry = true) => {
    const sessionToken = Cookies.get('session_token');
    if (!sessionToken) {
        throw new Error('No session token found');
    }
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                Authorization: `Bearer ${sessionToken}`,
                ...options.headers,
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch');
        }
        return response;
    } catch (error) {
        try {
            await refreshToken();
        } catch (refreshError) {
            console.error('Error refreshing token:', refreshError);
            throw refreshError;
        }
        if (retry) {
            const newSessionToken = Cookies.get('session_token');
            return await fetchWithToken(url, options, false);
        }
        throw error;
    }
};

export const refreshToken = async () => {
    let sessionToken = Cookies.get('session_token');
    if (!sessionToken) {
        throw new Error('No session token found');
    }
    Cookies.remove('session_token');
    const response = await fetch(`${DATABASE_API_URL}/refresh_token`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${sessionToken}`,
        },
    });
    if (!response.ok) {
        if (response.text() === 'No refresh token available') {
            throw new Error('No refresh token available');
        }
        console.error('Failed to refresh token', await response.text());
        throw new Error('Failed to refresh token');
    }
    const respObj = await response.json();
    sessionToken = respObj.session_token;
    Cookies.set('session_token', sessionToken);
};
