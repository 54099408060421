import React, { useState } from 'react';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';

import { generateAudioWithParsedDialogue, parseDialogueFromScript } from '../../utils/structureScript';
import { div } from 'three/nodes';
import { constructPublicUrl } from '../../utils/s3';

const SoundsView = ({ data, setActiveTab, setError, updateData, setLoading, setLoadingText }) => {
    const [activeScene, setActiveScene] = useState(0);

    const navigate_to_script = () => {
        setActiveTab('Script');
    };

    const generate_dialogue = async () => {
        setLoading(true);
        setLoadingText('Parsing dialogue...');

        const hasNullVoiceId = data.characters.some(character => !character.voice_id);

        if (hasNullVoiceId) {
            setError('You must select a voice for all characters');
            setActiveTab('Characters');
        }

        const scenes = data.script.scenes.map(scene => scene.scene_text);
        const updatedParsedSceneItems = []; // Initialize an empty array to hold the updated cameras

        for (let index = 0; index < scenes.length; index++) {
            const scene = scenes[index];
            const characters = data.characters;
            const parsedDialogueItems = await parseDialogueFromScript(scene, characters, setError);

            updatedParsedSceneItems[index] = parsedDialogueItems.dialogues;
            updateData(updatedParsedSceneItems);
        }

        for (let index = 0; index < scenes.length; index++) {
            const characters = data.characters;
            updatedParsedSceneItems[index] = await generateAudioWithParsedDialogue(updatedParsedSceneItems[index], characters, setError);
            updateData(updatedParsedSceneItems);
        }

        setLoading(false);
        setLoadingText('');
    };

    const DialogueBox = ({ instance }) => {
        const renderInfo = (label, value) =>
            value ? (
                <p>
                    <strong>{label}:</strong> {value}
                </p>
            ) : null;

        return (
            <div className="camera-card-body">
                <h2>{instance.character}</h2>

                {renderInfo('Line', instance.line)}
                {renderInfo('Parenthetical', instance.parenthetical)}
                <div className="mt-4">
                    {instance.audio_url && (
                        <audio controls>
                            <source src={instance.audio_url} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    )}
                </div>
            </div>
        );
    };

    if (data?.characters.length === 0) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                <h2>Please parse characters before adding dialogue to the project.</h2>
                <Button onClick={navigate_to_script}>Parse my Characters</Button>
            </div>
        );
    } else if (data?.cameras.length === 0) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                <Button onClick={generate_dialogue}>Generate my Dialogue</Button>
            </div>
        );
    } else {
        console.log(data.cameras);
        return (
            <div className="App-content">
                <Row className="justify-content-md-center">
                    <Col xs={10} md={10} className="border p-4 shadow rounded position-relative">
                        <Tabs activeKey={activeScene} onSelect={k => setActiveScene(k)}>
                            {data.script.scenes?.map((scene, index) => (
                                <Tab eventKey={index} key={index} title={<>Scene {index + 1} </>}></Tab>
                            ))}
                        </Tabs>

                        <Container fluid className="p-0">
                            <Button onClick={generate_dialogue} className="mt-3">
                                Re-Generate Dialogue
                            </Button>

                            {activeScene < data.cameras.length &&
                                data.cameras[activeScene]?.map(
                                    (instance, index) =>
                                        instance.type === 'Dialogue' && (
                                            <div key={index} className="mt-3 border p-3 shadow-sm rounded position-relative">
                                                <DialogueBox instance={instance} />
                                            </div>
                                        )
                                )}
                        </Container>
                    </Col>
                </Row>
            </div>
        );
    }
};

export default SoundsView;
