import React from 'react';
import './ErrorInformation.css';

const ErrorInformation = ({ errorText }) => {
    return (
        <div className="error-modal">
            <p>{errorText}</p>
        </div>
    );
};

export default ErrorInformation;
