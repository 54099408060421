import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getEnvVariable } from '../utils/env';

const UserContext = createContext(undefined);

const DATABASE_API_URL = getEnvVariable('REACT_APP_DATABASE_API_URL');
const OAUTH_CLIENT_ID = getEnvVariable('REACT_APP_OAUTH_CLIENT_ID');

export const UserProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState(null);

    const setUserDetailsCookies = data => {
        Cookies.set('user_email', data.email);
        Cookies.set('user_name', data.name);
        Cookies.set('user_given_name', data.given_name);
        Cookies.set('user_picture', data.picture);
        Cookies.set('session_token', data.sessionToken);
    };

    const resetUserDetailsCookies = () => {
        Cookies.remove('user_email');
        Cookies.remove('user_name');
        Cookies.remove('user_given_name');
        Cookies.remove('user_picture');
        Cookies.remove('session_token');
    };

    const getUserDetailsFromDatabase = async sessionToken => {
        const response = await fetch(`${DATABASE_API_URL}/get_user?session_token=${sessionToken}`);
        const data = await response.json();
        if (data.error || data.email === null) {
            setError(data.error);
            setIsLoggedIn(false);
            return;
        }
        const newUserDetails = {
            email: data.email,
            name: data.name,
            givenName: data.given_name,
            picture: data.picture,
            sessionToken: sessionToken,
        };
        setUserDetails(newUserDetails);
        setUserDetailsCookies(newUserDetails);
        setIsLoggedIn(true);
    };

    const getUserDetailsFromCookies = () => {
        const email = Cookies.get('user_email');
        const name = Cookies.get('user_name');
        const givenName = Cookies.get('user_given_name');
        const picture = Cookies.get('user_picture');
        let sessionToken = Cookies.get('session_token');
        if (sessionToken === '' || sessionToken === null || sessionToken === 'undefined') {
            sessionToken = undefined;
        }
        return { email, name, givenName, picture, sessionToken };
    };

    const getUserDetailsFromUrl = () => {
        const url = new URL(window.location.href);
        const error = url.searchParams.get('error');
        if (error) {
            return { error };
        }
        let sessionToken = url.searchParams.get('session_token');
        if (sessionToken === '' || sessionToken === null || sessionToken === 'undefined') {
            sessionToken = undefined;
        }
        const email = url.searchParams.get('email');
        const name = url.searchParams.get('name');
        const givenName = url.searchParams.get('given_name');
        const picture = url.searchParams.get('picture');
        return { sessionToken, email, name, givenName, picture };
    };

    useEffect(() => {
        let newUserDetails = getUserDetailsFromUrl();
        if (newUserDetails.error) {
            setIsLoggedIn(false);
            setError(newUserDetails.error);
            return;
        }
        if (!newUserDetails.sessionToken) {
            newUserDetails = getUserDetailsFromCookies();
        }
        if (!newUserDetails.sessionToken || newUserDetails.sessionToken === '' || newUserDetails.sessionToken === null) {
            setIsLoggedIn(false);
            return;
        }

        if (!newUserDetails.email || !newUserDetails.name || !newUserDetails.givenName || !newUserDetails.picture || !newUserDetails) {
            getUserDetailsFromDatabase(newUserDetails.sessionToken);
            return;
        } else {
            setUserDetails(newUserDetails);
            setUserDetailsCookies(newUserDetails);
        }
        setIsLoggedIn(true);
        setLoading(false);
        setError(null);
    }, []);

    const login = () => {
        const baseUrl = 'https://accounts.google.com/o/oauth2/auth';
        const params = {
            redirect_uri: `${DATABASE_API_URL}/auth/google/callback`,
            response_type: 'code',
            client_id: OAUTH_CLIENT_ID,
            scope: 'openid email profile',
            access_type: 'offline',
            state: `redirect_uri=${window.location.origin}`,
            prompt: 'consent', // TODO REMOVE THIS AFTER EVERYONE HAS LOGGED IN
        };
        const queryParams = new URLSearchParams(params);
        const targetUrl = `${baseUrl}?${queryParams.toString()}`;
        window.location.href = targetUrl;
    };

    const logout = () => {
        setUserDetails({});
        setIsLoggedIn(false);
        resetUserDetailsCookies();
    };

    return (
        <UserContext.Provider
            value={{
                userDetails,
                sessionToken: userDetails.sessionToken,
                error,
                setError,
                setUserDetails,
                isLoggedIn,
                setIsLoggedIn,
                loading,
                getUserDetailsFromDatabase,
                getUserDetailsFromCookies,
                login,
                logout,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
