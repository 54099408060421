import JSZip from 'jszip';

class PickfordPackage {
    constructor() {
        this.packageBlobs = {};
    }

    addScriptJson(scriptJson) {
        this.packageBlobs['script.json'] = new Blob([scriptJson], { type: 'application/json' });
    }

    addScriptText(scriptText) {
        this.packageBlobs['script.txt'] = new Blob([scriptText], { type: 'text/plain' });
    }

    async addCharacter(blobUrl, characterName, fileSuffix = 'glb') {
        const response = await fetch(blobUrl);
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
        const fileName = `${characterName.toLowerCase().replace(/ /g, '_')}.${fileSuffix}`;
        this.packageBlobs[`characters/${fileName}`] = blob;
    }

    async compressPackage() {
        const zip = new JSZip();
        Object.keys(this.packageBlobs).forEach(key => {
            zip.file(key, this.packageBlobs[key]);
        });
        return await zip.generateAsync({ type: 'blob' });
    }
}

export default PickfordPackage;
