import React, { useState } from 'react';
import './ViewRenderedOutput.css';
import UnityPlayer from '../Renderer/Renderer';
import { Button, Modal } from 'react-bootstrap';

const ViewRenderedOutput = ({ showModal, zipUrl, setShowModal }) => {
    const [videoUrl, setVideoUrl] = useState('');
    const handleDownloadVideo = () => {
        const a = document.createElement('a');
        a.href = videoUrl;
        a.download = 'video.mp4';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Clean up
        URL.revokeObjectURL(zipUrl);
    };
    const handleDownloadAssets = () => {
        const a = document.createElement('a');
        a.href = zipUrl;
        a.download = 'package.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Clean up
        URL.revokeObjectURL(zipUrl);
    };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Rendered Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ width: '100%' }}>
                    <UnityPlayer packageZipURL={zipUrl} setVideoUrl={setVideoUrl} />
                </div>
                <Button variant="primary" onClick={handleDownloadVideo} disabled={!videoUrl}>
                    Download Video
                </Button>
                <Button variant="secondary" onClick={handleDownloadAssets} disabled={!zipUrl}>
                    Download 3D Assets
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default ViewRenderedOutput;
