import React from 'react';
import './Advanced.css';
import Form from 'react-bootstrap/Form';

const Advanced = ({ data, updateData }) => {
    const handleChange = (field, value) => {
        updateData({
            ...data,
            [field]: value,
        });
    };

    return (
        <Form>
            <Form.Group className="m-3">
                <Form.Label>Genre</Form.Label>
                <Form.Select value={data.genre || ''} onChange={e => handleChange('genre', e.target.value)}>
                    <option>Action</option>
                    <option>Comedy</option>
                    <option>Drama</option>
                    <option>Fantasy</option>
                    <option>Horror</option>
                    <option>Mystery</option>
                    <option>Romance</option>
                    <option>Sci-Fi</option>
                    <option>Thriller</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Tone</Form.Label>
                <Form.Range min="0" max="10" value={data.tone || ''} onChange={e => handleChange('tone', e.target.value)} />
                <div className="d-flex justify-content-between slider-label text-secondary">
                    <span>Cheerful</span>
                    <span>Serious</span>
                </div>
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Plot</Form.Label>
                <Form.Control placeholder="Define the plot of your story." value={data.plot || ''} onChange={e => handleChange('plot', e.target.value)} />
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Setting</Form.Label>
                <Form.Control placeholder="The location and era of your story." value={data.setting || ''} onChange={e => handleChange('setting', e.target.value)} />
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Cinematography</Form.Label>
                <Form.Select value={data.cinematography || ''} onChange={e => handleChange('cinematography', e.target.value)}>
                    <option>Low-key</option>
                    <option>High-key</option>
                    <option>Natural</option>
                    <option>Expressionist</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Theme</Form.Label>
                <Form.Select value={data.theme || ''} onChange={e => handleChange('theme', e.target.value)}>
                    <option>Existentialism</option>
                    <option>Love</option>
                    <option>Good vs Evil</option>
                    <option>Redemption</option>
                    <option>Courage</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Pace</Form.Label>
                <Form.Range min="0" max="10" value={data.pace || ''} onChange={e => handleChange('pace', e.target.value)} />
                <div className="d-flex justify-content-between text-secondary">
                    <span>Slow</span>
                    <span>Fast</span>
                </div>
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Dialogue Style</Form.Label>
                <Form.Select value={data.dialog_style || ''} onChange={e => handleChange('dialogStyle', e.target.value)}>
                    <option>Victorian English</option>
                    <option>Modern English</option>
                    <option>Slang</option>
                    <option>Formal</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Ending</Form.Label>
                <Form.Control placeholder="Do you have an ending you want the story to eventually get to?" value={data.ending || ''} onChange={e => handleChange('ending', e.target.value)} />
            </Form.Group>
        </Form>
    );
};

export default Advanced;
