import React, { useState, useEffect } from 'react';
import './ScriptView.css';
import { Tabs, Tab, Form, Container, Row, Col, Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';

const ScriptView = ({ data, updateData, parseCharacters }) => {
    const [activeScene, setActiveScene] = useState(0);

    useEffect(() => {
        if (activeScene >= data.scenes.length) {
            setActiveScene(data.scenes.length - 1);
        } else if (activeScene < 0 && data.scenes.length > 0) {
            setActiveScene(0);
        }
    }, [activeScene, data.scenes.length]);

    const removeScene = index => {
        const updatedScenes = data.scenes.filter((_, i) => i !== index);
        updateData({ ...data, scenes: updatedScenes });
    };

    const handleSceneContentChange = event => {
        const newScenes = [...data.scenes];
        newScenes[activeScene].scene_text = event.target.value;
        updateData({ ...data, scenes: newScenes });
    };

    const addEmptyScene = () => {
        const newScenes = [...data.scenes, { scene_text: '', scene_id: null }];
        updateData({ ...data, scenes: newScenes });
        setActiveScene(newScenes.length - 1);
    };

    const downloadScript = () => {
        const combinedScript = data.scenes.map(scene => scene.scene_text).join('\n\n');
        const blob = new Blob([combinedScript], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'script.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="script-view">
            <Container>
                {data.scenes.length > 0 && (
                    <Row className="justify-content-md-center">
                        <Col xs={10} md={10} className="border p-4 shadow rounded position-relative">
                            <Tabs activeKey={activeScene} onSelect={k => setActiveScene(k)}>
                                {data.scenes.map((scene, index) => (
                                    <Tab
                                        eventKey={index}
                                        key={index}
                                        title={
                                            <>
                                                Scene {index + 1}{' '}
                                                <AiOutlineClose
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        removeScene(index);
                                                    }}
                                                />
                                            </>
                                        }
                                    >
                                        <Form>
                                            <Form.Group className="m-3">
                                                <Form.Control as="textarea" rows="25" value={scene.scene_text} onChange={handleSceneContentChange} style={{ fontFamily: 'Courier New' }} />
                                            </Form.Group>
                                        </Form>
                                    </Tab>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>
                )}
                <Row className="mt-3">
                    <Col className="justify-content-start">
                        <Button variant="primary" onClick={addEmptyScene}>
                            Add Scene
                        </Button>{' '}
                        {data.scenes.length > 0 && (
                            <Button variant="secondary" onClick={downloadScript}>
                                Download Script
                            </Button>
                        )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                        {data.scenes.length > 0 && (
                            <Button variant="success" onClick={parseCharacters}>
                                Parse Characters
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ScriptView;
