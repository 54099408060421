import React from 'react';
import './LoadingInformation.css';

const LoadingInformation = ({ text }) => {
    return (
        <div className="loading-information">
            <p>{text}</p>
            <div className="loading-bar">
                <div className="loading-progress"></div>
            </div>
        </div>
    );
};

export default LoadingInformation;
