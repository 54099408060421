import { getEnvVariable } from './env';

const OPENAI_API_KEY = getEnvVariable('REACT_APP_OPENAI_API_KEY');
const IMAGE_TO_3D_URL = `${getEnvVariable('REACT_APP_MAIN_API_URL')}/image_to_3d`;
const RIGGING_URL = `${getEnvVariable('REACT_APP_MAIN_API_URL')}/rig_character`;

// Function to generate 2D image from text
export const textToImage = async (prompt, setLoading, setLoadingText, setError, { responseFormat = 'url' } = {}) => {
    const apiUrl = 'https://api.openai.com/v1/images/generations'; // Replace with your actual API URL

    const imageParams = {
        model: 'dall-e-3',
        prompt: prompt,
        size: '1024x1024',
        quality: 'standard',
        n: 1,
        response_format: responseFormat,
        style: 'natural',
    };

    try {
        setLoading(true);
        setLoadingText('Generating 2D image...');

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`,
            },
            body: JSON.stringify(imageParams),
        });

        const imagesResponse = await response.json();

        if (responseFormat === 'b64_json') {
            // Decode the base64 image data
            const base64Image = imagesResponse.data[0].b64_json;
            const imageDecoded = atob(base64Image);
            return imageDecoded;
        } else {
            return imagesResponse.data[0].url;
        }
    } catch (error) {
        setError(`Error generating image: ${error}`);
        setTimeout(() => {
            setError('');
        }, 10000);
    } finally {
        setLoading(false);
        setLoadingText('');
    }
};

// Function to generate 3D image from 2D image
export const imageTo3D = async (imageUrl, setLoading, setLoadingText, setError) => {
    try {
        setLoading(true);
        setLoadingText('Generating 3D image...');

        const response = await fetch(`${IMAGE_TO_3D_URL}?url=${encodeURIComponent(imageUrl)}`, {
            method: 'GET',
        });

        if (!response.ok) {
            setError('Failed to generate 3D model.');
            setTimeout(() => {
                setError('');
            }, 10000);
        }

        const { zip_file_url, obj_file_name, mtl_file_name, img_file_name, glb_file_url } = await response.json();
        return { zip_file_url, obj_file_name, mtl_file_name, img_file_name, glb_file_url };
    } catch (error) {
        setError(`Error generating 3D model: ${error}`);
        setTimeout(() => {
            setError('');
        }, 10000);
    } finally {
        setLoading(false);
        setLoadingText('');
    }
};

export const rigCharacter = async (zipFileUrl, objFileName, setError) => {
    const formData = new FormData();

    try {
        const data = {
            obj_file_name: objFileName,
            zip_file_url: zipFileUrl,
        };

        const response = await fetch(RIGGING_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {
            const data = await response.json();
            const modelFileUrl = data.model_file_url;
            return modelFileUrl;
        } else {
            setError(`Error with request: Status code ${response.status}`);
            setTimeout(() => {
                setError('');
            }, 10000);
            return null;
        }
    } catch (error) {
        setError(`Error with file upload: ${error}`);
        setTimeout(() => {
            setError('');
        }, 10000);
    }
};
