import { getEnvVariable } from './env';

const MAIN_API_URL = getEnvVariable('REACT_APP_MAIN_API_URL');

export const generate_scene = async (isBasicInput, isFirst, data) => {
    let url;
    let request_body;

    if (isFirst) {
        url = `${MAIN_API_URL}/generate_first_scene`;
        request_body = isBasicInput ? JSON.stringify({ premise: data.story.basic_prompt }) : JSON.stringify(data.story.advanced_prompt);
    } else {
        url = `${MAIN_API_URL}/generate_additional_scene`;
        request_body = JSON.stringify({
            premise: { premise: isBasicInput ? data.story.basic_prompt : data.story.advanced_prompt },
            existing_script: data.script.scenes,
        });
    }

    console.log('REQUEST BODY', request_body);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: request_body,
    });

    const result = await response.json();

    return result.scene;
};

export const generate_metadata = async data => {
    const url = `${MAIN_API_URL}/generate_story_metadata`;

    const request_body = JSON.stringify({ request: data.script.scenes.map(scene => scene.scene_text).join(' NEW SCENE ') });

    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: request_body,
    });

    return await response.json();
};
