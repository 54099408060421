import React from 'react';
import './SelectionBar.css';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const SelectionBar = ({ activeTab, setActiveTab }) => {
    const comingSoon = ['Sets'];

    return (
        <div className="App-tabs">
            <ButtonGroup>
                {['Story', 'Script', 'Characters', 'Dialogue', 'Sets', 'Cameras'].map((tab, index) => (
                    <Button key={tab} variant="outline-secondary" className={activeTab === tab ? 'active' : ''} onClick={() => setActiveTab(tab)} disabled={comingSoon.includes(tab)} title={comingSoon.includes(tab) ? 'Coming Soon...' : tab}>
                        {tab}
                    </Button>
                ))}
            </ButtonGroup>
        </div>
    );
};

export default SelectionBar;
